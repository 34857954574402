import React from "react";

export interface StepProps {
    title: string;
    heading: string;
    description: JSX.Element;
    img: string;
    alternate: boolean;
}

export const Step = ({ title, heading, description, img, alternate }: StepProps) => {
    const containerStyle = "items-center flex flex-col lg:flex-row";

    const textSideStyle = "w-full lg:px-5 lg:m-20 lg:mt-10 lg:flex-col lg:items-start ";
    const titleStyle = "justify-center text-xl font-semibold text-center text-primaryText lg:justify-content-start lg:flex lg:-mb-3 lg:text-lg lg:font-bold lg:text-start lg:px-5";
    const headingStyle = "py-3 text-3xl font-semibold leading-relaxed text-center text-secondary lg:flex lg:font-bold lg:text-left lg:justify-content-start lg:p-5";
    const descriptionStyle = "px-10 text-md text-center text-gray-400 lg:flex lg:text-left lg:px-5 lg:text-lg lg:leading-10";

    const imageContainerStyle = "m-10 overflow-hidden w-4/5 flex lg:w-full";
    const imageStyle = "flex w-full lg:mt-24 lg:mb-20 lg:object-scale-down";



    return (
        <div>
            {alternate ? (
                <div className={containerStyle.concat(" lg:flex-row-reverse")}>
                    <div className={imageContainerStyle.concat(" lg:mr-20")} >
                        <img className={imageStyle} src={img} alt="" />
                    </div>
                    <div className={textSideStyle}>
                        <p className={titleStyle}>{title}</p>
                        <p className={headingStyle}>{heading}</p>
                        <p className={descriptionStyle}>{description}</p>
                    </div>
                </div >) : (
                <div className={containerStyle.concat(" lg:flex-row")}>
                    <div className={imageContainerStyle.concat(" lg:ml-20")} >
                        <img className={imageStyle} src={img} alt="" />
                    </div>
                    <div className={textSideStyle}>
                        <p className={titleStyle}>{title}</p>
                        <p className={headingStyle}>{heading}</p>
                        <p className={descriptionStyle}>{description}</p>
                    </div>
                </div>
            )}
        </div>
    );
};