import React from "react";
import { DataView } from "primereact/dataview";
import { gql, useQuery } from "@apollo/client";
import { ShopItem } from "../../gql/graphql";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import {Card } from "primereact/card";
import { Link } from "react-router-dom";
import { WIP } from "../general/WIP";

const SHOP_ITEMS_QUERY = gql`
query shopItems {
  shopItems {
    id
    title
    description
    imageIds
    tags {
    	name
    }
    sizing {
        priceXS
    }
  }
}`;


export const ShopItemListDisplay = () => {
    const { data, loading, error } = useQuery(SHOP_ITEMS_QUERY);

    const gridItem = (shopItem: ShopItem) => {
        const detailsPath = "/item/" + shopItem.id;
        return (
            <Link to={detailsPath} className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4 p-2 no-underline">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <div className="flex align-items-center gap-2">
                            <i className="pi pi-tag"></i>
                            <span className="font-semibold">{shopItem.title}</span>
                        </div>
                        <Tag value={shopItem.title} severity="success"></Tag>
                    </div>
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <img className="w-9 shadow-2 border-round" src={"https://primefaces.org/cdn/primereact/images/product/bamboo-watch.jpg"} />
                        <div className="text-2xl font-bold">{shopItem.title}</div>
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <span className="text-2xl font-semibold">€{shopItem.sizing?.priceXS}</span>
                        <Button
                            icon="pi pi-shopping-cart"
                            className="p-button-rounded"
                            onClick={()=> {
                                let cart: {id:string,count:number}[] = JSON.parse(localStorage.getItem("cart") || "[]");
                                let cartContains = false;
                                cart = cart.map(item => {
                                    if(item.id === shopItem.id as string) {
                                        cartContains = true;
                                        return {id:item.id, count:item.count + 1};
                                    } else {
                                        return item;
                                    }
                                });
                                if(!cartContains) cart.push({ id: shopItem.id as string, count: 1 }) ;
                                localStorage.setItem("cart",JSON.stringify(cart));
                                console.log(localStorage.getItem("cart"));
                            }} />
                    </div>
                </div>
            </Link>
        );
    };

    const itemTemplate = (shopItem: ShopItem) => {
        if (!shopItem) {
            return;
        }
        return gridItem(shopItem);
    };

    if(error) return <WIP />;
    if(loading) return <div>Loading...</div>;
    return (
        <Card className="m-4">
            <h2>Shop</h2>
            <DataView value={data.shopItems} itemTemplate={itemTemplate} layout="grid" />
        </Card>
    );
};
