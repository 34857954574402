abstract class Constants {
    static readonly BACKEND_IP = "localhost"; //35.187.9.233
    static readonly BASE_URL = "http://" + this.BACKEND_IP + ":8080/";
    static readonly OAUTH2_REDIRECT_URI = "http://" + this.BACKEND_IP + ":3000/oauth2/redirect";
    static readonly GOOGLE_AUTH_URL = Constants.BASE_URL + "oauth2/authorize/google?redirect_uri=" + Constants.OAUTH2_REDIRECT_URI;

    static readonly LOCAL_BACKEND_IP = "localhost";
    static readonly LOCAL_BASE_URL = "http://" + this.LOCAL_BACKEND_IP + ":8080/";
    static readonly LOCAL_OAUTH2_REDIRECT_URI = "http://" + this.LOCAL_BACKEND_IP + ":3000/oauth2/redirect";
    static readonly LOCAL_GOOGLE_AUTH_URL = Constants.LOCAL_BASE_URL + "oauth2/authorize/google?redirect_uri=" + Constants.LOCAL_OAUTH2_REDIRECT_URI;
}

const prod = {
    url: {
        BACKEND_IP: Constants.BACKEND_IP,
        BASE_URL: Constants.BASE_URL,
        OAUTH2_REDIRECT_URI: Constants.OAUTH2_REDIRECT_URI,
        GOOGLE_AUTH_URL: Constants.GOOGLE_AUTH_URL,
    }
};
const dev = {
    url: {
        BACKEND_IP: Constants.LOCAL_BACKEND_IP,
        BASE_URL: Constants.LOCAL_BASE_URL,
        OAUTH2_REDIRECT_URI: Constants.LOCAL_OAUTH2_REDIRECT_URI,
        GOOGLE_AUTH_URL: Constants.LOCAL_GOOGLE_AUTH_URL,
    }
};
export const config_constants = process.env.NODE_ENV == "development" ? dev : prod;