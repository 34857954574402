import { gql, useQuery } from "@apollo/client";
import { Card } from "primereact/card";
import React from "react";
import { useParams } from "react-router-dom";


const SHOP_ITEM_QUERY = gql`
query Item ($id: ID!){
    shopItemById(id: $id) {
        id
        title
        description
        imageIds
        tags {
            name
        }
        sizing {
            priceXS
        }
    }
}`;

export const ShopItemDetails = () => {
    const { id } = useParams();
    const { data, loading, error } = useQuery(SHOP_ITEM_QUERY, {variables: { id }});

    if(error) return <div>Error</div>;
    if(loading) return <div>Loading...</div>;
    return (
        <div className="m-3">
            <Card>
                <div>
                    {data.shopItemById.title}
                </div>
            </Card>
        </div>
    );
};