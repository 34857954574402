import React from "react";
import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";
import { useAppSelector } from "../../util/hooks";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNone } from "../../util/privilegeSlices";
import { loggedOut } from "../../util/loginStatusSlices";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/webp/Sinpratex_Logo.webp";



export const Navigation = () => {
    const currentUser = useAppSelector(state => state.privilege.value);
    const isAdmin = currentUser === "ADMIN";
    const isLoggedIn = useAppSelector(state => state.loggedIn.value);

    const isShopReady = false;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function logout() {
        localStorage.removeItem("token");
        dispatch(setNone());
        dispatch(loggedOut());
    }

    const minItems: MenuItem[] = [
        {
            label: "Home",
            icon: "pi pi-home",
            url: "/"
        }
    ];


    const items: MenuItem[] = [
        {
            label: "Home",
            icon: "pi pi-home",
            url: "/"
        },
        {
            label: "Shop",
            icon: "pi pi-shopping-cart",
            url: "/shop"
        }
    ];
    const adminItems: MenuItem[] = [
        {
            label: "Create",
            icon: "pi pi-plus-circle",
            url: "/item/create"
        }
    ];
    const itemsLoggedOut: MenuItem[] = [
        {
            label: "Login",
            icon: "pi pi-user",
            url: "/login"
        },
    ];
    const itemsLoggedIn: MenuItem[] = [
        {
            label: "Profil",
            icon: "pi pi-user",
            url: "/profile"
        },
        {
            label: "Logout",
            icon: "pi pi-power-off",
            url: "/login",
            command: () => {
                logout();
                navigate("/login");
            }
        },
    ];

    const startContent = (
        <Link to="/">
            <img src={Logo} className="h-16"></img>
        </Link>
    );

    return (
        <div className="sticky top-0">
            {isShopReady ?
                isAdmin ?
                    <Menubar className="flex flex-grow-1 p-1" model={items.concat(adminItems, itemsLoggedIn)} start={startContent} /> :
                    isLoggedIn ?
                        <Menubar className="flex flex-grow-1 p-1" model={items.concat(itemsLoggedIn)} start={startContent} /> :
                        <Menubar className="flex flex-grow-1 p-1" model={items.concat(itemsLoggedOut)} start={startContent} />
                :
                <Menubar className="flex flex-grow-1 p-1" model={minItems} start={startContent} />
            }
        </div>);
};

