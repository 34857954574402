import { createSlice } from "@reduxjs/toolkit";

export const privilege = createSlice({
    name: "privilege",
    initialState: {
        value: ""
    },
    reducers: {
        setAdmin: state => {
            state.value = "ADMIN";
        },
        setUser: state => {
            state.value = "USER";
        },
        setNone: state => {
            state.value = "";
        }
    }
});

export const { setAdmin, setUser, setNone } = privilege.actions;

export default privilege.reducer;