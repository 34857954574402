import "./App.css";
import React from "react";
import { ShopItemListDisplay } from "./components/shop/ShopItemListDisplay.component";
import { Routes, Route } from "react-router-dom";
import { Login } from "./components/auth/Login.component";
import { Navigation } from "./components/general/Navigation.component";
import { Profile } from "./components/user/Profile.component";
import { Signup } from "./components/auth/Signup.component";
import { Home } from "./components/home/Home.component";
import { OAuth2RedirectHandler } from "./components/auth/Oath2RedirectHandler.component";
import { ShopItemDetails } from "./components/shop/ShopItemDetails.component";
import { CreateShopItem } from "./components/admin/CreateShopItem.component";
import { Footer } from "./components/general/Footer";
import { Impressum } from "./components/general/Impressum";
import { Materials } from "./components/home/Materials";
import { WIP } from "./components/general/WIP";
import { Gallery } from "./components/home/Gallery";

export default function App() {

    return (
        <div>
            <Navigation />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/contact" element={<Impressum />} />
                <Route path="/materials" element={<Materials />} />
                <Route path="/gallery" element={<Gallery />} />

                {/* Shop */}
                <Route path="/shop" element={<ShopItemListDisplay />} />
                <Route path="/item/:id" element={<ShopItemDetails />} />

                {/* Auth */}
                <Route path="/login" element={<WIP />} />
                <Route path="/signup" element={<WIP />} />
                <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />

                {/* User */}
                <Route path="/profile" element={<Profile />} />

                {/* Admin */}
                <Route path="/item/create" element={<CreateShopItem />} />
                <Route path="/admin" element={<Profile />} />
            </Routes>
            <Footer />
        </div>
    );
}


