import React from "react";
import { TitleStep, TitleStepProps } from "./TitleStep";
import { Step, StepProps } from "./Step";
import SinpratexBlue from "../../assets/webp/Sinpratex-Blue.webp";
import GripsCine from "../../assets/webp/Grips-Cine.webp";
import SandPile from "../../assets/webp/material.webp";
import { Link } from "react-router-dom";

interface Data {
    titleStep: TitleStepProps,
    steps: StepProps[]
}

export const Home = () => {
    const data: Data = {
        titleStep: {
            description: "Fertiger und Massgeschneiderter 3D-Druck",
        },
        steps: [
            {
                title: "3D-Druck",
                heading: "3D-Druck im SLS Verfahren",
                description: <p>Lasersintern ist ein generatives Schichtbauverfahren: das Werkstück wird Schicht für Schicht aufgebaut. Durch die Wirkung der Laserstrahlen können so beliebige dreidimensionale Geometrien auch mit Hinterschneidungen erzeugt werden, z. B. Werkstücke, die sich in konventioneller mechanischer oder gießtechnischer Fertigung nicht herstellen lassen.</p>,
                img: SinpratexBlue,
                alternate: false,
            },
            {
                title: "Unser Angebot",
                heading: "Individuelle und Fertige Produkte",
                description: <p>In unserem Shop finden Sie, Produkte aus allen Bereichen. Bei individuellen Wünschen und Produkten helfen wir Ihnen bei der Erstellung von Modellen, bis zum fertigen Produkt</p>,
                img: GripsCine,
                alternate: true,
            },
            {
                title: "",
                heading: "Material",
                description: <p>Für unsere Drucke verwenden wir Nylon in industrieller Qualität. Für weitere Details <Link to="/materials" className="text-secondary">hier klicken.</Link></p>,
                img: SandPile,
                alternate: false,
            },
        ],
    };
    return (
        <div className="box-border">
            <div className="flex flex-col gap-y-20">

                <TitleStep
                    description={data.titleStep.description}
                />

                {data.steps.map(step => {
                    return (
                        <>
                            <div id="divider" className="rounded-full ring-2 ring-gray-200"></div>
                            <Step
                                key={step.title}
                                title={step.title}
                                heading={step.heading}
                                description={step.description}
                                img={step.img}
                                alternate={step.alternate}
                            />
                        </>
                    );
                })}
                <div id="divider" className="rounded-full ring-2 ring-gray-200"></div>
            </div>
        </div>
    );
};