import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <div className="flex flex-row justify-center h-screen py-6 lg:pt-24 lg:pb-14 lg:bg-cover lg:h-full">
            <Link to="/contact" className="px-5 text-xl text-center lg:font-medium">Kontakt</Link>
            <Link to="/impressum" className="px-12 text-xl text-center lg:font-medium">Impressum</Link>
        </div>
    );
};