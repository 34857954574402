import React from "react";

export const Impressum = () => {
    return (
        <div className="flex flex-col gap-4 justify-center h-screen p-10 lg:pt-24 lg:pb-14 lg:bg-cover lg:h-full">
            <div>
                <h2 className="text-orange-500">Impressum</h2>
                <p className="text-gray-500">Informationen gemäß den Anforderungen des Telemediengesetzes (TMG)</p>
            </div>

            <div>
                <h3 className="text-orange-500">Verantwortlich</h3>
                <span className="text-gray-500">
                    Sinpratex GbR<br/>
                    <br/>
                    vertreten durch<br/>
                    <br/>
                    Braunsperger Alexander (Geschäftsführer)<br/>
                    Braunsperger Michael (Geschäftsführer)<br/>
                </span>
            </div>

            <div>
                <h3 className="text-orange-500">Kontakt</h3>
                <span className="text-gray-500">
                    Telefon: +49 176 615 782 54 <br/>
                    E-Mail: info@sinpratex.com<br/>
                    <br/>
                    Sitz: 84533 Stammham, Kirchenstrasse 36<br/>
                    <br/>
                    Umsatzsteuer - Identifikationsnummer(USt - IdNr.)<br/>
                    DE 359059656
                </span>
            </div>

            <div>
                <h3 className="text-orange-500">Rechtliche Hinweise, Qualitätssicherung, Haftungsausschluss</h3>
                <span className="text-gray-500">
                    Sinpratex ist bemüht, auf ihrer Website stets richtige, aktuelle und vollständige Informationen bereitzustellen und ändert oder ergänzt diese daher bei Bedarf laufend und ohne vorherige Ankündigung.
                    <br/>Dennoch müssen wir für Korrektheit, Aktualität und Vollständigkeit jede Gewähr, Haftung oder Garantie ausschließen.<br/>
                    Dies gilt auch für alle Verweise, so genannte &#39;Hyperlinks&#39;, die Sinpratex auf ihrer Website direkt oder indirekt anbietet.<br/>
                    Sinpratex kann für die Inhalte solcher externer Internet - Auftritte, die Sie mittels eines Links oder sonstiger Hinweise erreichen, keine Verantwortung übernehmen.<br/>
                    Ferner haftet Sinpratex nicht für direkte oder indirekte Schäden (inkl.entgangener Gewinne), die auf Informationen zurückgeführt werden können, die auf diesen externen Internet - Auftritten stehen.<br/>
                    <br/>
                    Alle Rechte vorbehalten. Alle Texte, Bilder, Grafiken, Ton -, Video - und Animationsdateien unterliegen dem Urheberrecht und anderen Gesetzen zum Schutz geistigen Eigentums.<br/>
                    Der Inhalt dieser Website darf ohne vorherige Zustimmung nicht kopiert, verbreitet, verändert oder auf sonstige Art genutzt werden.<br/>
                </span>
            </div>
        </div>
    );
};

