import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loggedInReducer from "./loginStatusSlices";
import privilegeReducer from "./privilegeSlices";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
    key: "root",
    storage: storageSession,
};

const rootReducer = combineReducers({
    loggedIn: loggedInReducer,
    privilege: privilegeReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunk]
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

