import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface Material {
    name: string;
    color: string;
    tensile: number;
    tensileMod: number;
    elongationBreak: number;
    flexural: number;
    flexuralMod: number;
    impact: number;
    hardness: number;
}

export const Materials = () => {
    const materials: Material[] = [
        {
            name: "Polyamid, PA12 (Nylon)",
            color: "Grau",
            tensile: 52.3,
            tensileMod: 1840,
            elongationBreak: 7.8,
            flexural: 60.6,
            flexuralMod: 1380,
            impact: 29.5,
            hardness: 75,
        }
    ];


    return (
        <div className="m-6 flex flex-col items-center">
            <h2 className="mb-6 align-center text-4xl text-secondary font-semibold">Material-Eigenschaften</h2>
            <DataTable value={materials} tableStyle={{ minWidth: "50rem" }}>
                <Column field="name" header="Name"></Column>
                <Column field="color" header="Farbe"></Column>
                <Column field="tensile" header="Zugfestigkeit (MPa)"></Column>
                <Column field="tensileMod" header="Zugfestigkeitsmodul (nach Young) (MPa)"></Column>
                <Column field="elongationBreak" header="Bruchdehnung (%)"></Column>
                <Column field="flexural" header="Biegefestigkeit (MPa)"></Column>
                <Column field="flexuralMod" header="Biegefestigkeitsmodul (MPa)"></Column>
                <Column field="impact" header="Schlagzähigkeit (nach Charpy, ungekerbt) (kJ/m²)"></Column>
                <Column field="hardness" header="Shore Härte (D Skala)"></Column>
            </DataTable>
        </div>
    );
};