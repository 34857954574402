import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loggedIn } from "../../util/loginStatusSlices";


export const OAuth2RedirectHandler = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const token = searchParams.get("token");
    if (token) {
        localStorage.setItem("token", token);
        dispatch(loggedIn());
    }

    return <div>
        <Navigate to="/profile?provided=true" />
    </div>;
};