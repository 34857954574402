import React from "react";
import ReactDOM from "react-dom/client";
import "primereact/resources/primereact.min.css";
import "./index.css";
import "./theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from "@apollo/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { config_constants } from "./util/constants";
import { Provider } from "react-redux";
import { persistor, store } from "./util/store";
import { PersistGate } from "redux-persist/integration/react";

const link = createHttpLink({
    uri: config_constants.url.BASE_URL + "graphql",
    credentials: "same-origin"
});

const token = localStorage.getItem("token");

const authMiddleware: ApolloLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (token) {
        operation.setContext({
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
    }

    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authMiddleware.concat(link),
    headers: {
        Accept: "*",
        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
        authorization: token ? `Bearer ${token}` : "",
    }
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </ApolloProvider>
);


function getCookie(name: string): string {
    let cookieValue = "";
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + "=")) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}