import React from "react";
import { Galleria } from "primereact/galleria";
import SinpratexBlue from "../../assets/webp/Sinpratex-Blue.webp";
import GripsCine from "../../assets/webp/Grips-Cine.webp";
import SandPile from "../../assets/webp/material.webp";


export const Gallery = () => {
    const images = [SinpratexBlue, GripsCine, SandPile];

    const itemTemplate = (item: string) => {
        return <img src={item} alt="" style={{ width: "100%" }} />;
    };

    const thumbnailTemplate = (item: string) => {
        return <img src={item} alt="" />;
    };

    return (
        <div className="box-border">
            <div className="m-10 text-secondary flex justify-center text-6xl font-bold">Gallerie</div>
            <div className="grid grid-flow-row auto-rows-auto gap-10 justify-items-center" >
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} showThumbnails={false} showIndicators />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <div className="max-w-sm">
                    <Galleria value={images} numVisible={5}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
            </div>
        </div>
    );
};