import { gql, useMutation, useQuery } from "@apollo/client";
import { Field, Form, Formik, useFormik } from "formik";
import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../util/hooks";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { PickList, PickListChangeEvent } from "primereact/picklist";
import { Tag } from "primereact/tag";
import { ItemTag } from "../../gql/graphql";


const CREATE_SHOP_ITEM_MUTATION = gql`
mutation createShopItem($input: CreateShopItemInput!) {
    createShopItem(input: $input) {
        success
        shopItem {
            title
        }
    }
}`;

const CREATE_ITEM_TAG_MUTATION = gql`
mutation createItemTag($input: CreateItemTagInput!) {
    createItemTag(input: $input) {
        success
        itemTag {
            id
            name
        }
    }
}`;

const TAGS_QUERY = gql`
query itemTags {
  itemTags {
    id
    name
  }
}`;

export const CreateShopItem = () => {
    const [createShopItem] = useMutation(CREATE_SHOP_ITEM_MUTATION);
    const [createItemTag] = useMutation(CREATE_ITEM_TAG_MUTATION);
    const { data, loading, error } = useQuery(TAGS_QUERY);
    const privilege = useAppSelector(state => state.privilege.value);
    const isAdmin = privilege === "ADMIN";
    const navigate = useNavigate();

    if (!isAdmin) {
        navigate("/");
    }

    const [source, setSource] = useState<string[]>([]);
    const [target, setTarget] = useState<string[]>([]);

    useEffect(() => {
        if (data && data.itemTags) {
            setSource(data.itemTags.map((tag: ItemTag) => tag.name) || []);
        }
    }, [data]);

    const onChange = (event: PickListChangeEvent) => {
        setSource(event.source);
        setTarget(event.target);
    };

    const formik = useFormik({
        initialValues: {
            title: "Neues Produkt",
            description: "",
            imageIds: [],
            tags: [],
            sizing: {
                priceXS: 0,
                priceS: 0,
                priceM: 0,
                priceL: 0,
                priceXL: 0,
                descriptionXS: "",
                descriptionS: "",
                descriptionM: "",
                descriptionL: "",
                descriptionXL: ""
            },
        },
        onSubmit: values => {
            console.log(target);
            createShopItem({
                variables: {
                    input: {
                        title: values.title,
                        description: values.description,
                        imageIds: [],
                        tags: target,
                        sizing: {
                            priceXS: values.sizing.priceXS,
                            priceS: values.sizing.priceS,
                            priceM: values.sizing.priceM,
                            priceL: values.sizing.priceL,
                            priceXL: values.sizing.priceXL,
                            descriptionXS: values.sizing.descriptionXS,
                            descriptionS: values.sizing.descriptionS,
                            descriptionM: values.sizing.descriptionM,
                            descriptionL: values.sizing.descriptionL,
                            descriptionXL: values.sizing.descriptionXL
                        },
                    }
                },
                onCompleted: () => navigate("/shop")
            });
        },
    });

    const tagFormik = useFormik({
        initialValues: {
            newItemTag: ""
        },
        onSubmit: (values, actions) => createItemTag({
            variables: {
                input: {
                    name: values.newItemTag
                }
            },
            onCompleted: actions.resetForm
        })
    });

    if (error) return <div>Error</div>;
    if (loading) return <div>Loading...</div>;

    const sizingNodes: string[] = [
        "XS", "S", "M", "L", "XL"
    ];

    const tagTemplate = (tag: string) => {
        return (
            <Tag>{tag}</Tag>
        );
    };

    return (
        <div className="m-3">
            <Card>
                <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                    <label>Titel</label>
                    <InputText id="title" name="title" onChange={formik.handleChange} value={formik.values.title} />

                    <label>Beschreibung</label>
                    <InputTextarea id="description" name="description" onChange={formik.handleChange} value={formik.values.description} />

                    <div className="grid mt-4">
                        <label className="col-1">Größe</label>
                        <label className="col-3">Preis</label>
                        <label className="col-8">Beschreibung</label>
                    </div>
                    {sizingNodes.map(node => {
                        const refDescription = `sizing.description${node}`;
                        const refPrice = `sizing.price${node}`;
                        let valuePrice;
                        switch (node) {
                            case "XS": valuePrice = formik.values.sizing.priceXS;
                                break;
                            case "S": valuePrice = formik.values.sizing.priceS;
                                break;
                            case "M": valuePrice = formik.values.sizing.priceM;
                                break;
                            case "L": valuePrice = formik.values.sizing.priceL;
                                break;
                            case "XL": valuePrice = formik.values.sizing.priceXL;
                                break;
                        }
                        let valueDescription;
                        switch (node) {
                            case "XS": valueDescription = formik.values.sizing.descriptionXS;
                                break;
                            case "S": valueDescription = formik.values.sizing.descriptionS;
                                break;
                            case "M": valueDescription = formik.values.sizing.descriptionM;
                                break;
                            case "L": valueDescription = formik.values.sizing.descriptionL;
                                break;
                            case "XL": valueDescription = formik.values.sizing.descriptionXL;
                                break;
                        }
                        return (
                            <div key={node} className="grid">
                                <label className="col-1">{node}</label>
                                <InputNumber className="col-3" id={refPrice} name={refPrice} value={valuePrice} onChange={value => formik.setFieldValue(refPrice, value.value)} />
                                <InputTextarea className="col-8" id={refDescription} name={refDescription} value={valueDescription} onChange={formik.handleChange} />
                            </div>
                        );
                    })}

                </form>
                <div className="flex gap-6 mt-6 mb-4">
                    <label className="mt-4">Tags</label>
                    <form onSubmit={tagFormik.handleSubmit} className="flex flex-grow-1 gap-2 align-items-center justify-content-center">
                        <label>Tag erstellen</label>
                        <InputText id="newItemTag" name="newItemTag" value={tagFormik.values.newItemTag} onChange={tagFormik.handleChange} />
                        <Button icon="pi pi-plus" type="submit"> </Button>
                    </form>
                </div>
                <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                    <PickList source={source} target={target} onChange={onChange} itemTemplate={tagTemplate} breakpoint="800px"
                        sourceHeader="Available" targetHeader="Selected" sourceStyle={{ height: "30rem" }} targetStyle={{ height: "30rem" }} />


                    <label>Bilder</label>
                    <InputText id="imageId" name="imageId" />

                    <Button type="submit" className="mt-2">Erstellen</Button>
                </form>
            </Card>
        </div>
    );
};