import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../util/hooks";
import { useQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Role } from "../../gql/graphql";
import { Tag } from "primereact/tag";
import { setAdmin, setUser } from "../../util/privilegeSlices";
import { useDispatch } from "react-redux";


const USER_QUERY = gql`
query currentUser {
    currentUser {
        email
        emailVerified
        id
        imageUrl
        provider
        roles {
            id
            role
        }
        username
  }
}`;

export const Profile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const provided = Boolean(params.get("provided")) || false;
    const isLoggedIn = useAppSelector(state => state.loggedIn.value);
    const { data, loading, error } = useQuery(USER_QUERY);

    if (!isLoggedIn) {
        navigate("/login");
    }

    if (error) return <div>Error</div>;
    if (loading) return <div>Loading...</div>;

    if (provided && data.currentUser.username === "anonymousUser") {
        window.location.reload();
    }

    if (provided) {
        if (data.currentUser.roles.map((role: Role) => role.role).includes("ADMIN")) {
            dispatch(setAdmin());
        } else {
            dispatch(setUser());
        }
    }

    return (
        <Card className="m-4">
            <h2>Nutzer</h2>
            <div className="flex flex-column mt-2">
                <label>Name</label>
                <InputText value={data.currentUser?.username ?? ""} />
            </div>
            <div className="flex flex-column mt-2">
                <label>Email</label>
                <InputText value={data.currentUser?.email ?? ""} />
            </div>
            <div className="flex flex-column mt-2">
                <label>Rollen</label>
                <div>
                    {data.currentUser?.roles && data.currentUser?.roles.map((role: Role) => {
                        return <Tag key={role.id} value={role.role} className="mt-2 mr-2" />;
                    })}
                </div>
            </div>
        </Card>
    );
};