import React from "react";
import Logo from "../../assets/webp/Sinpratex_Logo.webp";
import Schrift from "../../assets/webp/Sinpratex_Schrift.webp";

export interface TitleStepProps {
    description: string;
}

export const TitleStep = ({ description }: TitleStepProps) => {
    return (
        <div id="product">
            <div style={{ textShadow: "0px 1px 1px gray" }} className="flex flex-col items-center justify-start lg:pt-10 lg:pb-20 bg-titlestep bg-center bg-cover bg-no-repeat">
                <div className="flex justify-center">
                    <img className="w-9" src={Logo}></img>
                </div>
                <div className="flex justify-center">
                    <img className="w-10" src={Schrift}></img>
                </div>
                <div>
                    <p className="p-4 pt-6 text-2xl leading-10 text-center">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
};